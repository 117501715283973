@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  background-color: white !important;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Noto Sans TC', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui {
  font-family: 'Noto Sans TC', sans-serif !important;
}

/* Mobile */

@media only screen and (max-width: 767px) {
  .company-slogan {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
  }
  .company-name {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
  }

  .big-heading {
    font-weight: 900;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company-slogan {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.4rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1.2 rem;
  }
}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .company-slogan {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .company-slogan {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 4rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 2rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 1.4rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1rem;
  }
}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {
  .company-slogan {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 800;
  }

  .company-name {
    font-size: 5rem;
    color: #ffffff;
    font-weight: 900;
  }

  .big-heading {
    font-weight: 900;
    font-size: 3rem;
  }

  .large-text {
    font-weight: 200;
    font-size: 2rem;
  }

  .small-text {
    font-weight: 200;
    font-size: 1.2rem;
  }
}

.load-from-below {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.upper-shadow {
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.2) !important;
}

.offer_button:hover {
  box-shadow: 2px 2px 4px blue !important;
  -webkit-box-shadow: 0px 0px 4px blue !important;
  -moz-box-shadow: 2px 2px 4px blue !important;
  -o-box-shadow: 2px 2px 4px blue !important;
  color: black !important;
  background-color: white !important;
}

.coderobo-about-background {
  background: url('/assets/banners/about_coderobo_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.codingrobo-background {
  background: url('/assets/banners/coding_banner-min.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.lp-about-background {
  background: url('/assets/adverts/stse_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* .aone-background {
  background: url('https://firebasestorage.googleapis.com/v0/b/aone-learnings.appspot.com/o/aone-background.jpg?alt=media&token=8da2b6d6-6c64-459b-acd6-f9cfe8d01a3b');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */

.aone-background {
  position: relative;
  overflow: hidden; /* Ensure the pseudo-element doesn't exceed the container */
}

.aone-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/logo.png');
  background-size: 100px 100px; /* Adjust the size of each grid cell */
  background-repeat: repeat;
  filter: blur(2px) brightness(50%); /* Apply blur and darken the image */
  /* z-index: -1; Place the pseudo-element behind the content */
}
.franchise-background {
  background: url('https://firebasestorage.googleapis.com/v0/b/aone-learnings.appspot.com/o/partner.jpg?alt=media&token=e69f1c80-abbc-4148-9f37-f0e82f07a0a5');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.dotted-background {
  background: url('https://firebasestorage.googleapis.com/v0/b/aone-learnings.appspot.com/o/dotted_background.jpg?alt=media&token=3a4a7ce0-5d86-441b-872b-c897ac6d701d') !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.backscroll-image {
  position: fixed;
  z-index: -5;
  object-fit: cover;
}

.dark-tint {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  opacity: 0.5;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}
.orange-tint {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  background-color: #3180b6;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.car-type-car-names {
  display: block;
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transform: scaleY(0) translateY(-100%);
  -moz-transform: scaleY(0) translateY(-100%);
  -webkit-transform: scaleY(0) translateY(-100%);
  transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  background-color: black;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.car-type-image-container {
  position: relative;
}

.car-type-image-container:hover > .orange-tint,
.car-type-image-container:hover > .car-type-car-names {
  transform: scaleY(1) translateY(0);
}

.dark-tint-text {
  color: white;
  display: 'table-cell';
  vertical-align: middle;
}

.hover-image {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: #d35f29;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-filter: alpha(opacity=90);
  text-align: center;
}

.hover-image:hover {
  width: 0%;
  height: 0%;

  background-color: #f5bd20;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-filter: alpha(opacity=100);

  transform: scale(0.7, 0.7);
  -webkit-transform: scale(0.7, 0.7);
  -moz-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);

  transition: all 750ms;
  -o-transition: all 750ms;
  -moz-transition: all 750ms;
  -webkit-transition: all 750ms;
}

.no-shadow-border {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none !important;
  border-radius: 0px !important;
}

.rotate_on_hover:hover {
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  -ms-transform: rotate(-40deg); /* IE 9 */
  -webkit-transform: rotate(-40deg); /* Safari */
  transform: rotate(-40deg);
}

.shrink_on_hover:hover {
  transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transform: scale(0.8, 0.8); /* IE 9 */
  -webkit-transform: scale(0.8, 0.8); /* Safari */
  transform: scale(0.8, 0.8);
}

.rise_on_hover:hover {
  cursor: pointer;
  transition: all 20ms ease-in-out;
  -webkit-transition: all 20ms ease-in-out;
  -moz-transition: all 20ms ease-in-out;
  -o-transition: all 20ms ease-in-out;
  -ms-transform: translate(0px, -4px) scale(1.1, 1.1); /* IE 9 */
  -webkit-transform: translate(0px -4px) scale(1.1, 1.1); /* Safari */
  transform: translate(0px, -4px) scale(1.1, 1.1);
}

#u_0_1 {
  display: none;
}

.opposite-curve {
  border-radius: 0px 40px 0px 40px !important;
  border-bottom: 2px solid red !important;
  border-top: 2px solid red !important;
}

.shadow-one:hover {
  -moz-box-shadow: 0px 0px 10px 5px rgba(68, 68, 68, 0.2) !important;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(68, 68, 68, 0.2) !important;
  box-shadow: 0px 0px 10px 5px rgba(68, 68, 68, 0.2) !important;
}

.highlight-on-hover:hover {
  cursor: pointer;
  background-color: #ccedf0 !important;
}

.orange-gradient {
  background: #f7971e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffd200,
    #f7971e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.light-blue-gradient {
  background: #3180b6 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3180b6,
    #66c0c9
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3180b6,
    #66c0c9
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.dark-transparent-gradient {
  background: transparent !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #000000,
    transparent
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #000000,
    transparent
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;

  opacity: 0.9;
  filter: alpha(opacity=70);
  -webkit-filter: alpha(opacity=70);
  text-align: center;
}

.black-color {
  background-color: #000000;
}

.news-img {
  margin: '5% 5%';
}
.img-responsive {
  width: 50%;
  float: left;
  height: 50%;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(+50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

p,
div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Shadow for Segments */
.breathing-shadow {
  border: 1px solid #ebe9e9 !important;
  border-radius: '20px' !important;
  box-shadow:
    0px 10px 30px rgba(0, 0, 0, 0.07),
    0px 10px 30px rgba(0, 0, 0, 0.07) !important;
}

.qualities-design {
  background:
    url('/assets/misc/eq_bg.png') 0% top / cover no-repeat,
    linear-gradient(262deg, rgb(0, 159, 255) 1%, rgb(131, 22, 189) 100%) left
      top no-repeat;
}

.count-font {
  font-family: 'Pacifico', cursive;
}
